/* @font-face {
  font-family: Attila-Semibold;
  font-display: auto;
  src:
    local('AttilaSansUniform-Semibold'),
    url('/fonts/AttilaSansUniform-Semibold.woff2') format('woff2'),
    url('/fonts/AttilaSansUniform-Semibold.woff') format('woff');
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: Attila-Bold;
  font-display: auto;
  src:
    local('AttilaSansUniform-Bold'),
    url('/fonts/AttilaSansUniform-Bold.woff2') format('woff2'),
    url('/fonts/AttilaSansUniform-Bold.woff') format('woff');
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: Attila-Regular;
  font-display: auto;
  src:
    local('AttilaSansUniform-Regular'),
    url('/fonts/AttilaSansUniform-Regular.woff2') format('woff2'),
    url('/fonts/AttilaSansUniform-Regular.woff') format('woff');
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: Labil-Regular;
  font-feature-settings: 'calt' off;
  font-display: auto;
  src:
    local('StabilGrotesk-Regular'),
    url('/fonts/StabilGrotesk-Regular.woff2') format('woff2'),
    url('/fonts/StabilGrotesk-Regular.woff') format('woff');
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: Labil-Bold;
  font-feature-settings: 'calt' off;
  font-display: auto;
  src:
    local('StabilGrotesk-Bold'),
    url('/fonts/StabilGrotesk-Bold.woff2') format('woff2'),
    url('/fonts/StabilGrotesk-Bold.woff') format('woff');
  font-style: normal;
  unicode-range: U+000-5FF;
} */
