:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
    'Courier New', monospace;

  .ant-pagination .ant-pagination-item-active {
    border-color: #e1ae72;

    a {
      color: #e1ae72;
    }
  }

  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #e1ae72;
  }

  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #e1ae72;
  }

  // --foreground-rgb: #FFF;
  // --background-start-rgb: #FFF;
  // --background-end-rgb: #FFF;
  // // back ground color
  // --app-home-bg-rgb: #F1DAB3;
  // --app-line-bg-rgb: #F2F2F2;
  // --app-input-bg-rgb: #FFF;
  // --app-home-gpt-bg-rgb: linear-gradient(to right, rgba(225,174,114,0.3), rgba(225,174,114,0.1));
  // --app-home-gpt-a-rgb: #E1AE72;
  // // title color
  // --app-text-color: rgb(0, 0, 0);
  // --app-text-60-color: rgba(0, 0, 0, 0.6);
  // --app-text-30-color: rgba(0, 0, 0, 0.3);

  // --foreground-rgb: #222;
  // --background-start-rgb: #222;
  // --background-end-rgb: #222;
  // // back ground color
  // --app-home-bg-rgb: #000;
  // --app-line-bg-rgb: rgba(242, 242, 242, 0.3);
  // --app-input-bg-rgb: #333;
  // --app-home-gpt-bg-rgb: rgba(208, 58, 58, 0.10);
  // --app-home-gpt-a-rgb: rgba(208, 58, 58, 1);//#E1AE72
  // // title color
  // --app-text-color: rgb(255, 255, 255);
  // --app-text-60-color: rgba(255, 255, 255, 0.6);
  // --app-text-30-color: rgba(255, 255, 255, 0.3);

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  // pietra theme colors
  --pietra-yellow: #f9e070;
  --app-link-hover: #ff401a;
  --pietra-pink: #fbf2f0;
  --off-white: #fafafa;

  --pietra-global-header-heigth: 64px;
  --pietra-global-mobile-header-heigth: 64px;

  --search-input-height: 40px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0 auto;
  color: rgb(var(--app-text-color));
  background: rgb(var(--foreground-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.utility-big {
  color: #000;
  font-family: Attila-Semibold;
  font-size: 16px;
  line-height: 18px;
}

.utility-small {
  color: #141414;
  font-family: Labil-Regular;
  font-size: 12px;
  line-height: 18px;
  -webkit-appearance: none;
}

@media (min-width: 320px) {
  /* Used for large headlines and branded moments. */
  .xl-headline,
  h1,
  .h1 {
    color: #000;
    font-family: Attila-Semibold;
    font-size: 42px;
    line-height: 100%;
    font-weight: normal;
  }

  /* Used for medium headlines and branded moments. */
  h2,
  .h2 {
    color: #000;
    font-family: Attila-Semibold;
    font-size: 34px;
    line-height: 100%;
    font-weight: normal;
  }

  /* Used for introductory text, such as a blog post or editorial page. */
  h3,
  .h3 {
    color: #141414;
    font-family: Attila-Semibold;
    font-size: 22px;
    line-height: 100%;
    font-weight: normal;
  }

  /* Our main body text and is used for long blocks of copy such as blog posts and descriptions,
     as well as product descriptions within a PDP. */
  .body-text {
    color: #141414;
    font-family: Labil-Regular;
    font-size: 17px;
    line-height: 22px;
  }

  .body-title {
    color: #141414;
    font-family: Labil-Bold;
    font-size: 17px;
    line-height: 22px;
  }

  /* This text is our main body text and is used for long blocks of copy such as blog posts and
     descriptions, as well as product descriptions within a PDP. */
  body,
  html,
  .body-text-small {
    color: #141414;
    font-family: Labil-Regular !important;
    font-size: 15px !important;
    line-height: 20px !important;
    font-feature-settings: 'calt' off !important;
  }
}

@media (min-width: 768px) {
  /* Used for the headline on the homepage. */
  .xl-headline {
    font-family: Attila-Semibold;
    font-size: 75px;
    line-height: 100%;
    color: #000;
  }

  /* Used for large headlines and branded moments. */
  h1,
  .h1 {
    color: #141414;
    font-family: Attila-Semibold;
    font-size: 60px;
    line-height: 100%;
  }

  /* Used for medium headlines and branded moments. */
  h2,
  .h2 {
    color: #000;
    font-family: Attila-Semibold;
    font-size: 42px;
    line-height: 100%;
  }

  /* Used for introductory text, such as a blog post or editorial page. */
  h3,
  .h3 {
    color: #000;
    font-family: Attila-Semibold;
    font-size: 24px;
    line-height: 100%;
  }

  /* This text is used with short blocks of copy or when larger body copy is needed. */
  .body-text {
    color: #141414;
    font-family: Labil-Regular;
    font-size: 15px;
    line-height: 20px;
  }

  /* This text is our main body text and is used for long blocks of copy such as blog posts
     and descriptions, as well as product descriptions within a PDP. */
  body,
  html,
  .body-text-small {
    color: #141414;
    font-family: Labil-Regular !important;
    font-size: 16px !important;
    line-height: 18px;
    font-feature-settings: 'calt' off !important;
  }
}

