:root {
  --foreground-rgb: #222;
  --background-start-rgb: #222;
  --background-end-rgb: #222;
  // back ground color
  --app-home-bg-rgb: #000;
  --app-line-bg-rgb: rgba(242, 242, 242, 0.3);
  --app-input-bg-rgb: #333;
  --app-home-gpt-bg-rgb: rgba(208, 58, 58, 0.1);
  --app-home-gpt-a-rgb: rgba(208, 58, 58, 1); //#E1AE72
  --app-share-bg-rgb: #333;
  // title color
  --app-text-color: rgb(255, 255, 255);
  --app-text-60-color: rgba(255, 255, 255, 0.6);
  --app-text-30-color: rgba(255, 255, 255, 0.3);

  // link
  --app-link-color: #369eff;

  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(255, 255, 255, 0.3);
  }

  .ant-pagination .ant-pagination-prev button {
    color: white;
  }
  .ant-pagination .ant-pagination-next button {
    color: white;
  }

  .ant-pagination .ant-pagination-item a {
    color: white;
  }

  .ant-pagination .ant-pagination-item-active a {
    color: #e1ae72;
  }

  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: white;
  }

  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: white;
  }

  .next-antd-rate-star-second {
    color: #666;
  }
}

